import { useEffect, useState, useCallback } from 'react';
import './App.css';

function Post({ title, username, content }) {
  return (
    <div className="post">
      <div className="post_title">
        <span>{title}</span>
      </div>
      <div className="post_author">
        <span>{username}</span>
      </div>
      <div className="post_content">
        {content}
      </div>
    </div>
  );
}

function App() {
  const [posts, setPosts] = useState([]);
  const [title, setTitle] = useState('');
  const [username, setUsername] = useState('');
  const [content, setContent] = useState('');

  const fetchPosts = useCallback(() => {
    fetch(`${process.env.REACT_APP_API_ORIGIN}/posts`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        }

        throw new Error(res.statusText);
      })
      .then((json) => {
        console.log(24, json);
        if (json) {
          setPosts(json);
        }
      })
      .catch((error) => {
        console.error(error);
      })
  }, [])

  useEffect(() => {
    fetchPosts()
  }, [fetchPosts]);

  const submitPost = (e) => {
    e.preventDefault();
    if (!title || !content) {
      // don't post if there's nothing to post
      return;
    }

    fetch(`${process.env.REACT_APP_API_ORIGIN}/posts`, {
      method: 'POST',
      body: JSON.stringify({
        title,
        content,
        author: username
      })
    })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }

      throw new Error(res.statusText);
    })
    .then((json) => {
      console.log(24, json);
      fetchPosts();
      setTitle('');
      setContent('');
    })
    .catch((error) => {
      console.error(error);
    });
  };

  return (
    <div className="App">
      {posts.map((post) => (
        <Post {...post} />
      ))}
      <h2>Make a post!</h2>
      <form onSubmit={submitPost} className="pure-form">
        <label htmlFor="username">Username:</label>
        <input id="username" className="form_input" value={username} onChange={(e) => setUsername(e.currentTarget.value)}/>

        <label htmlFor="title">Title:</label>
        <input id="title" className="form_input" value={title} onChange={(e) => setTitle(e.currentTarget.value)}/>

        <label htmlFor="content">Content:</label>
        <textarea id="content" className="form_input" value={content} onChange={(e) => setContent(e.currentTarget.value)} />

        <button className="pure-button-primary form_input" disabled={!(title && content && username)} type="submit">Post</button>
      </form>
    </div>
  );
}

export default App;
